import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { RiMailSendLine } from "react-icons/ri"
import { HiOutlineDocumentDuplicate } from "react-icons/hi"
import { BsPersonCheck } from "react-icons/bs"

import { FaViber } from "react-icons/fa"

const RuSteps = () => {
  const data = useStaticQuery(graphql`
    query {
      one: contentfulAsset(
        file: { fileName: { eq: "alexandre-boucher-CoUogz3Udak-unsplash.jpg" } }
      ) {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      two: contentfulAsset(
        file: { fileName: { eq: "scott-graham-OQMZwNd3ThU-unsplash.jpg" } }
      ) {
        fluid(maxWidth: 350) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      three: contentfulAsset(
        file: {
          fileName: { eq: "samuele-errico-piccarini-qAc3UNF8Hm4-unsplash.jpg" }
        }
      ) {
        fluid(maxWidth: 350) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  `)
  const backgroundFluidOne = [
    data.one.fluid,
    `linear-gradient(
      25deg, 
      rgba(128,0,128,0.2),
      rgba(35, 65, 97,1) 80%)`,
  ].reverse()

  const backgroundFluidTwo = [
    data.two.fluid,
    `linear-gradient(
      25deg, 
      rgba(128,0,128,0.2),
      rgba(35, 65, 97,1) 80%)`,
  ].reverse()

  const backgroundFluidThree = [
    data.three.fluid,
    `linear-gradient(
      25deg, 
      rgba(128,0,128,0.2),
      rgba(35, 65, 97,1) 80%)`,
  ].reverse()

  return (
    <section className="steps container py-5">
      <div className="title2 py-5 text-uppercase">Как работает центр?</div>
      <div className="row">
        <div className="col-sm-12 col-lg-4 pb-4 pb-md-0">
          <BackgroundImage className="topicon" fluid={backgroundFluidOne}>
            <RiMailSendLine />
          </BackgroundImage>
          <p className="step-title">Отправьте документы</p>
          <p className="step-description grayscale-hover">
            Получите бесплатную консультацию, подготовьте необходимые документы
            по списку и отправьте на{` `}
            <a
              className="viber"
              href="viber://chat/?number=%2B380683328808"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <FaViber />
              Viber
            </a>
            , или электронную почту.
          </p>
        </div>
        <div className="col-sm-12 col-lg-4 pb-4 pb-md-0">
          <BackgroundImage className="topicon" fluid={backgroundFluidTwo}>
            <HiOutlineDocumentDuplicate />
          </BackgroundImage>
          <p className="step-title">Подготовка лицензионного дела</p>
          <p className="step-description grayscale-hover">
            После получения документов мы подготавливаем лицензионное дело, чтоб
            как можно быстрее получить лицензию. Ваше личное присутствие не
            требуется.
          </p>
        </div>
        <div className="col-sm-12 col-lg-4 pb-4 pb-md-0">
          <BackgroundImage className="topicon" fluid={backgroundFluidThree}>
            <BsPersonCheck />
          </BackgroundImage>
          <p className="step-title">Получите документы и работайте</p>
          <p className="step-description grayscale-hover">
            Готовые документы передаем лично в руки или отправляем в ближайшее к
            вам отделение Новой почты или Укрпочты. Оплата услуг после
            оформления документов!
          </p>
        </div>
      </div>
    </section>
  )
}

export default RuSteps
