import React from "react"
import { graphql, Link } from "gatsby"

import Header from "../../components/Layout/header"
import Footer from "../../components/Layout/footer"
import ServiceCard from "../../components/CardService"
import Steps from "../../components/ruSteps"
import CallMeBack from "../../components/callback"
import SEO from "../../components/Global/MetaHead"

const IndexRuPage = ({ data: { licenses, transport, ogImage } }) => {
  return (
    <>
      <SEO
        title="Центр Лицензий Украины"
        description="Единое окно для получения лицензий, лицензия по почте за 10 дней, вся Украина. Оплата услуг при получении документов!"
        image={`https:${ogImage.fixed.src}`}
        url="/ru/"
        lang="ru"
      />
      <Header lang="ru" />

      <section className="licensies container mb-5">
        <div className="py-5 title2 text-uppercase">Выберите лицензию</div>
        <div className="card-columns">
          {licenses.nodes.map(license => (
            <ServiceCard
              key={license.id}
              serviceTitle={license.title}
              servicePrice={license.price}
              serviceValid={license.validity}
              serviceFrom={license.termFrom}
              serviceUpTo={license.termUpTo}
              serviceTax={license.taxAmount}
              serviceLink={`/ru/${license.seo.slug}/`}
              serviceFluidImage={license.seo.metaImage.fluid}
              serviceId={license.serviceId}
              lang="ru"
            />
          ))}
        </div>
      </section>

      <section className="container mb-5">
        <div className="py-5 title2 text-uppercase">
          Услуги для перевозчиков
        </div>
        <div className="card-columns">
          {transport.nodes.map(service => (
            <ServiceCard
              key={service.id}
              serviceTitle={service.title}
              servicePrice={service.price}
              serviceValid={service.validity}
              serviceFrom={service.termFrom}
              serviceUpTo={service.termUpTo}
              serviceTax={service.taxAmount}
              serviceLink={`/ru/${service.seo.slug}/`}
              serviceFluidImage={service.seo.metaImage.fluid}
              lang="ru"
            />
          ))}
        </div>
      </section>

      <section className="py-5 zakon">
        <div className="container">
          <h1 className="pb-5 text-center">Центр Лицензий</h1>
          <h2 className="pb-3">Закон Украины о лицензировании</h2>
          <p>
            Полный перечень перечень видов хозяйственной деятельности,
            подлежащих лицензированию, состоит из 33 пунктов и опубликован на{" "}
            <a
              href="https://zakon.rada.gov.ua/laws/show/222-19"
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="white-link"
            >
              официальном сайте Верховной Рады Украины
            </a>
            , текущая редакция от 19.08.2022.
          </p>
          <p>На период военного положения для всех видов лицензий:</p>
          <ul>
            <li>вдвое сокращен объем требуемых документов;</li>
            <li>отменено подтверждение трехлетнего опыта работы;</li>
            <li>отменены документы о материально-технической базе;</li>
            <li>срок рассмотрения заявки сокращен с 10 до 1-3 дней.</li>
          </ul>
          <p>Упрощение процедуры касается лицензий на:</p>
          <ul>
            <li>
              <Link to="/ru/licenziya-avtobus/" className="white-link">
                внутренние и международные перевозки пассажиров автобусами
              </Link>
              ;
            </li>
            <li>
              <Link to="/ru/licenziya-perevozka-adr/" className="white-link">
                внутренние и международные перевозки опасных грузов и опасных
                отходов грузовыми автомобилями
              </Link>
              ;
            </li>
            <li>
              <Link
                to="/ru/licenziya-mizhnarodni-perevezennya/"
                className="white-link"
              >
                международные грузоперевозки грузовыми автомобилями (кроме
                перевозка опасных грузов и опасных отходов)
              </Link>
              .
            </li>
          </ul>
          <h2 className="py-3">
            Кто выдает в транспортные лицензии в Украине?
          </h2>
          <p>
            Единственный орган, который выдает транспортные лицензии это
            Государственная Службы Украины по Безопасности на Транспорте
            (Укртрансбезопасность).
          </p>
          <h2 className="py-3">
            Какой штраф при работе без транспортной лицензии?
          </h2>
          <p>
            Наличие лицензии в Украине проверяют работники Укртрансбезопасности,
            в случае ее отсутности составляется административный протокол по
            статье 164 КУпАП (штраф от 17000 до 34000 грн).
          </p>
          <h2>Кто лицензирует медицинскую практику в Украине?</h2>
          <p>
            Лицензии на медицинскую практику выдает Министерство Здравоохранения
            Украины (МОЗ) по адресу Киев, ул. Грушевского, 7.
          </p>
        </div>
      </section>
      <Steps lang="ru" />
      <CallMeBack lang="ru" />
      <Footer lang="ru" slug="/" />
    </>
  )
}

export const query = graphql`
  query {
    ogImage: contentfulAsset(
      file: { fileName: { eq: "center-licenziy-opengraph-min.png" } }
    ) {
      title
      id
      fixed(width: 620, height: 460) {
        src
      }
    }
    services: allContentfulService(
      filter: { node_locale: { eq: "ru" } }
      sort: { fields: serviceId, order: ASC }
    ) {
      totalCount
      nodes {
        serviceId
        title
        id
        price
        ltdPrice
        termFrom
        termUpTo
        validity
        taxAmount
        type {
          type
        }
        seo {
          slug
          metaImage {
            fluid(maxWidth: 350) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    licenses: allContentfulService(
      filter: { node_locale: { eq: "ru" }, type: { type: { eq: "Лицензия" } } }
      sort: { fields: serviceId, order: ASC }
    ) {
      totalCount
      nodes {
        serviceId
        title
        id
        price
        ltdPrice
        termFrom
        termUpTo
        validity
        taxAmount
        type {
          type
        }
        seo {
          slug
          metaImage {
            fluid(maxWidth: 350) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    transport: allContentfulService(
      filter: { node_locale: { eq: "ru" }, type: { type: { eq: "Услуга" } } }
      sort: { fields: serviceId, order: ASC }
    ) {
      totalCount
      nodes {
        serviceId
        title
        id
        price
        ltdPrice
        termFrom
        termUpTo
        validity
        taxAmount
        type {
          type
        }
        seo {
          slug
          metaImage {
            fluid(maxWidth: 350) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default IndexRuPage
